import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import {People} from '~components/People'
import '~styles/flickity.css'
import * as Flickity from 'react-flickity-component'

const PeopleMarquee = ({ className, color }) => {

	let requestId
	let n = 0
	let flkty = null

	const play = () => {
		// Set the decrement of position x
		window.cancelAnimationFrame(requestId)
		if (flkty) {
			flkty.x = flkty.x - 0.5
			// Settle position into the slider
			flkty.settle(flkty.x)
			// Set the requestId to the local variable
			requestId = window.requestAnimationFrame(play)
		}
	}

	useEffect(()=> {
		play()
	}, [])

	useEffect(() => {
		flkty.x
	}, [flkty?.x])

	const flickityOptions = {
		prevNextButtons: false,
		pageDots: false,
		adaptiveHeight: false,
		wrapAround: true,
		cellAlign: 'left',
		freeScroll: true,
		contain: true,
		draggable: false
		// cellAlign: 'center',
	}

	return (
		<Wrap className={className} color={color}>
			<Inner>
				<Slider
					flickityRef={c => flkty = c}
					className={'carousel'} // default ''
					elementType={'div'} // default 'div'
					options={flickityOptions} // takes flickity options {}
					disableImagesLoaded={false} // default false
					// static // default false
					// css={sliderCss}
				>					
					{[...Array(5)].map(i => (
						<React.Fragment key={i}>
							<Slide>
								<MovingPeople/>
							</Slide>
						</React.Fragment>
					))}
				</Slider>
			</Inner>
		</Wrap>
	)
}

const Wrap = styled.div`
	overflow: hidden;
	width: 100%;
	border-radius: 100%;
	height: 0px;
	padding-bottom: 100%;
	position: relative;
	background-color: ${ props => `var(--${props.color})`};
`
const Inner = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
`
const Slider = styled(Flickity)`
	margin-top: 210px;
	${mobile}{
		margin-top: 100px;
	}
`
const Slide = styled.div`
`
const MovingPeople = styled(People)`
	height: 430px;
	${mobile}{
		height: 185px;
	}
`


PeopleMarquee.propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
}

export default PeopleMarquee