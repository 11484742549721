/* eslint-disable react/prop-types */
import React from 'react'

export const People = ({ className }) => (
	<svg viewBox="0 0 1738 430" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path d="M789.951 91.5893C790.659 92.8046 791.641 93.8363 792.819 94.6012C793.996 95.3662 795.337 95.843 796.732 95.9935C794.103 98.3603 789.443 98.0308 786.008 97.162C790.877 112.681 808.293 105.88 817.792 93.8963H848.113C855.82 103.633 875.835 115.108 881.481 97.102C877.448 98.1206 871.742 98.3903 869.532 94.4355C872.698 94.9149 875.506 93.1173 877.239 87.245C867.68 90.0913 866.544 83.3501 867.202 77.0584C868.755 62.0782 870.189 46.9482 866.903 36.6718C862.123 22.83 852.355 12.164 845.574 16.0289C828.815 4.04476 799.271 18.1262 797.061 49.6446C796.344 59.921 799.032 67.8905 799.54 76.9985C799.809 81.7622 797.539 93.8064 789.861 91.5294" fill="#38231B"/>
		<path d="M846.469 93.7163C867.111 95.0945 884.974 102.465 886.139 126.972L889.425 193.485L894.115 252.417L875.206 254.155L871.084 199.027L870.187 148.364C869.62 131.946 867.35 198.039 867.499 200.286C867.917 206.757 870.188 219.79 871.263 234.051H794.61C795.685 219.79 797.956 206.757 798.374 200.286C798.374 198.039 796.253 131.946 795.685 148.364L794.789 199.027L790.667 254.155L771.787 252.417L776.447 193.485L779.733 126.972C780.898 102.465 798.792 95.0945 819.404 93.7163H846.469Z" fill="#61BAFA"/>
		<path d="M775.672 252.237C777.823 267.397 778.48 272.611 785.47 274.498C787.441 274.888 788.457 274.228 788.457 272.551L789.473 253.645L775.672 252.237Z" fill="#663627"/>
		<path d="M890.053 252.237C887.902 267.397 887.245 272.611 880.255 274.498C878.313 274.888 877.268 274.228 877.268 272.551L876.252 253.645L890.053 252.237Z" fill="#663627"/>
		<path d="M862.78 234.051C863.168 240.552 863.347 247.264 863.138 253.765C862.302 278.722 858.239 310.69 855.999 337.025L850.024 422.592C849.845 429.573 834.072 430.801 835.088 422.143L833.744 249.9C833.744 241.811 832.399 241.811 832.31 249.9L830.876 422.113C831.862 430.771 815.94 429.543 815.94 422.562L809.965 336.995C807.725 310.72 803.662 278.692 802.825 253.735C802.616 247.234 802.825 240.522 803.184 234.021L862.78 234.051Z" fill="#663627"/>
		<path d="M817.791 93.8964L832.936 139.736L848.082 93.8964C844.169 92.4583 842.107 86.2265 841.808 81.7324C835.625 87.0953 831.025 86.8856 824.901 81.7324C824.692 86.2864 822.75 92.3085 817.791 93.7166" fill="#784836"/>
		<path d="M830.367 428.105H803.721V424.181C803.726 422.16 804.38 420.196 805.585 418.577C806.79 416.958 808.483 415.771 810.412 415.192H830.367V428.105Z" fill="#331C12"/>
		<path d="M835.506 428.105H862.152V424.181C862.149 422.164 861.5 420.202 860.3 418.584C859.101 416.966 857.415 415.777 855.491 415.192H835.506V428.105Z" fill="#331C12"/>
		<path d="M845.962 77.9571C850.502 72.8938 852.265 67.1713 852.922 57.4342C853.49 52.221 853.161 46.3787 848.949 43.4426C841.989 38.589 835.507 54.8875 814.327 47.697C813.49 47.4274 813.281 49.4047 813.281 51.7117C813.281 61.8383 813.819 71.0662 820.421 77.9571C830.518 88.4433 835.925 89.1024 845.962 77.9571Z" fill="#885240"/>
		<path d="M871.264 234.051C871.811 240.607 871.97 247.19 871.741 253.765C870.666 278.722 865.438 310.69 862.57 337.025H803.333C800.346 310.75 795.238 278.722 794.132 253.765C793.904 247.19 794.063 240.607 794.61 234.051H871.264Z" fill="#21283A"/>
		<path d="M672.312 335.707C674.075 343.137 674.613 345.684 680.348 346.613C681.991 346.793 682.768 346.613 682.798 345.654L683.634 336.396L672.312 335.707Z" fill="#663627"/>
		<path d="M760.437 335.707C758.674 343.137 758.137 345.684 752.371 346.613C750.758 346.793 749.981 346.613 749.952 345.654L749.115 336.396L760.437 335.707Z" fill="#663627"/>
		<path d="M714.163 366.866L714.342 424.779C714.013 429.333 702.841 429.004 702.692 425.019L696.389 366.866H714.163Z" fill="#663627"/>
		<path d="M736.299 366.866L730.176 425.109C729.996 429.094 719.063 429.423 718.525 424.869L718.705 366.956L736.299 366.866Z" fill="#663627"/>
		<path d="M714.342 428.105H695.553V425.888C695.553 423.222 700.093 421.574 702.842 420.735H714.342V428.105Z" fill="#1B110F"/>
		<path d="M718.525 428.105H737.315V425.888C737.315 423.222 732.775 421.574 730.026 420.735H718.525V428.105Z" fill="#1B110F"/>
		<path d="M743.318 288.519L747.889 336.636L761.69 335.797L753.983 272.88C753.027 260.896 726.082 258.409 727.575 259.128H705.29C706.784 258.409 679.839 260.896 678.883 272.88L671.176 335.797L684.977 336.636L689.518 288.519C689.518 286.602 690.025 288.01 690.533 291.276C691.739 301.841 692.407 312.46 692.535 323.094H740.331C740.283 313.001 740.852 302.916 742.034 292.893C742.691 288.549 743.199 286.332 743.318 288.519Z" fill="white"/>
		<path d="M719.152 196.331C727.606 195.402 738.599 197.859 742.722 211.491C744.843 218.532 743.588 224.973 743.289 232.463C742.931 241.601 743.289 247.114 746.456 253.436C742.124 255.263 732.386 255.114 726.65 254.335H706.277C700.303 255.144 690.505 255.203 686.412 252.807C691.939 243.818 689.937 241.541 689.579 232.404C689.28 224.794 688.025 218.352 690.146 211.431C694.388 197.35 711.923 193.455 719.152 196.271" fill="#1B110F"/>
		<path d="M724.111 251.338H708.756C708.703 254.301 707.891 257.201 706.396 259.757C709.384 264.521 712.968 266.888 716.434 266.918C719.899 266.948 723.364 264.521 726.471 259.757C724.976 257.201 724.164 254.301 724.111 251.338Z" fill="#4D291C"/>
		<path d="M710.28 252.537C707.032 250.515 704.161 247.939 701.796 244.927C699.645 242.091 698.192 238.785 697.554 235.28C696.568 234.714 695.764 233.878 695.235 232.87C694.705 231.861 694.474 230.723 694.567 229.587C694.567 227.07 695.702 225.303 697.226 225.303C697.241 225.033 697.241 224.763 697.226 224.494C697.405 223.026 698.331 222.157 700.392 222.007C708.697 221.768 714.343 221.738 719.152 221.798C719.451 221.798 719.869 219.58 720.168 219.58C720.467 219.58 720.646 221.798 720.944 221.798H723.036C723.902 221.798 724.768 217.184 725.605 217.184C726.441 217.184 726.919 221.827 727.606 221.827H732.087C733.431 221.827 735.343 222.277 735.612 223.865C735.612 224.344 735.612 224.823 735.612 225.303C737.105 225.303 738.271 227.07 738.271 229.587C738.364 230.723 738.132 231.861 737.603 232.87C737.074 233.878 736.269 234.714 735.283 235.28C734.646 238.785 733.192 242.091 731.041 244.927C728.676 247.939 725.805 250.515 722.558 252.537C717.3 255.952 715.508 255.952 710.25 252.537" fill="#663627"/>
		<path d="M740.332 323.244H692.536C692.327 328.637 692.386 344.575 692.834 349.609L694.776 382.206H715.179L715.597 354.133C715.597 351.886 716.045 350.747 716.434 350.747C716.822 350.747 717.24 351.886 717.27 354.133L717.688 382.206H738.091L740.033 349.609C740.511 344.575 740.541 328.637 740.332 323.244Z" fill="#21283A"/>
		<path d="M1038.91 222.217C1039.27 231.774 1039.18 259.997 1038.34 268.925L1028.09 422.592C1027.85 429.573 1000.1 430.802 1001.36 422.143L999.088 262.244C999.088 258.23 998.341 256.252 997.624 256.252C996.907 256.252 996.22 258.259 996.16 262.244L993.92 422.113C995.174 430.772 967.423 429.543 967.034 422.563L956.788 268.895C955.952 259.907 955.862 231.744 956.22 222.187L1038.91 222.217Z" fill="#F2A63C"/>
		<path d="M1015.07 85.687L1047.42 97.6712C1050.16 99.0053 1052.57 100.934 1054.47 103.318C1056.37 105.702 1057.72 108.481 1058.42 111.453L1063.46 141.743L1064.33 198.998L1062.57 254.514L1043.69 256.282V201.155L1040.34 153.967C1040.19 149.263 1038.73 150.402 1039.03 155.105C1038.28 187.732 1037.98 197.35 1039.03 221.708C1039.03 222.666 1039.03 223.745 1039.03 224.853H956.311C956.311 223.745 956.311 222.666 956.311 221.708C957.297 197.35 956.998 187.732 956.311 155.105C956.58 150.402 955.146 149.263 954.997 153.967L951.412 201.275V256.402L932.802 254.634L931.039 199.117L931.905 141.863L936.954 111.573C937.651 108.601 938.999 105.822 940.9 103.438C942.802 101.054 945.209 99.1252 947.947 97.7911L980.299 85.8069H997.715L1015.07 85.687Z" fill="white"/>
		<path d="M994.069 428.105H961.029V424.18C961.012 421.315 962.111 418.556 964.092 416.491C966.073 414.426 968.779 413.219 971.634 413.125H993.949L994.069 428.105Z" fill="#231F20"/>
		<path d="M937.043 255.203C940.03 273.179 941.016 279.172 948.006 281.538C949.978 282.018 950.993 281.209 950.844 279.141V256.342L937.043 255.203Z" fill="#663627"/>
		<path d="M1001.21 428.105H1034.37V424.18C1034.42 421.269 1033.33 418.454 1031.32 416.354C1029.3 414.253 1026.55 413.038 1023.64 412.975H1001.21V428.105Z" fill="#231F20"/>
		<path d="M1058.27 255.203C1055.28 273.179 1054.29 279.172 1047.33 281.538C1045.33 282.018 1044.35 281.209 1044.35 279.141V256.342L1058.27 255.203Z" fill="#663627"/>
		<path d="M978.863 86.1962C981.494 83.1371 983.249 79.4201 983.942 75.4404H1011.48C1012.15 79.4216 1013.9 83.1421 1016.53 86.1962L997.713 103.364L978.863 86.1962Z" fill="#4D291C"/>
		<path d="M997.714 8.65844C993.956 8.52365 990.209 9.13447 986.687 10.4558C983.165 11.7772 979.938 13.783 977.192 16.3583C971.874 21.9909 971.844 29.3012 972.083 36.5516C972.083 38.7987 972.352 41.1955 972.711 44.0717L970.291 45.9892C970.307 51.2652 971.206 56.5011 972.95 61.4788L975.339 62.8269L975.818 64.7444C977.819 72.3244 984.929 82.3612 992.367 85.7168C995.894 86.3462 999.504 86.3462 1003.03 85.7168C1010.47 82.3312 1017.58 72.2945 1019.58 64.7444L1020.09 62.8269L1022.45 61.4788C1024.19 56.5011 1025.09 51.2652 1025.11 45.9892L1022.69 44.0717C1023.05 41.0757 1023.23 38.7987 1023.31 36.5516C1023.55 29.3012 1023.55 21.9909 1018.21 16.3583C1015.46 13.7853 1012.24 11.7806 1008.73 10.4593C1005.21 9.13796 1001.47 8.52598 997.714 8.65844Z" fill="#663627"/>
		<path d="M971.635 45.0006C970.799 40.8962 970.329 36.7251 970.231 32.537C970.231 24.1182 969.514 17.9463 973.427 13.2425C976.176 9.97681 977.819 5.15317 979.014 4.25436C979.312 4.01468 979.731 5.45282 980.448 6.26175C982.001 7.93954 983.106 7.1905 983.076 5.39287C983.037 4.37638 982.866 3.36931 982.568 2.39683C982.43 1.72482 982.43 1.03154 982.568 0.359536C982.568 -0.0299504 983.017 0.0898914 983.345 0.359536C985.675 2.24705 986.332 4.0446 990.515 5.24301C990.23 4.35856 990.194 3.41276 990.41 2.5091C990.626 1.60545 991.086 0.778812 991.739 0.119801C995.683 7.93948 998.192 7.87957 998.998 6.59127C999.439 5.9017 999.828 5.18072 1000.16 4.43412C1000.16 4.19443 1000.37 4.22439 1000.82 4.43412C1004.67 10.1266 1009.3 9.31773 1013.79 11.3251C1018.82 14.4418 1022.45 19.3924 1023.91 25.1368C1025.05 28.5223 1024.27 39.398 1023.7 45.1504L1020.03 48.1464L1018.27 56.8949C1015.79 53.8989 1017.85 40.4166 1017.43 38.2295C1017.19 36.9712 1013.76 32.627 1006.05 33.0464C1000.88 33.3161 999.895 34.8141 993.203 34.1549C986.512 33.4958 980.866 31.5783 978.058 36.1922C975.788 39.9972 980.089 53.5992 976.475 56.2957L975.1 48.1464L971.635 45.0006Z" fill="black"/>
		<path d="M1373.6 29.2414C1373.6 24.0765 1371.56 19.1231 1367.91 15.471C1364.27 11.8188 1359.33 9.76709 1354.18 9.76709C1349.03 9.76709 1344.1 11.8188 1340.45 15.471C1336.81 19.1231 1334.77 24.0765 1334.77 29.2414C1334.78 32.3571 1335.5 35.4295 1336.86 38.2295C1319.89 39.0085 1305.73 52.2511 1304 72.2647C1304 95.4241 1321.68 94.6451 1328.97 106.839L1339.19 110.494V109.356L1347.97 106.36C1355.26 94.1357 1372.97 94.9447 1372.94 71.7853C1372.36 62.6957 1368.58 54.1083 1362.28 47.5473C1365.64 45.8455 1368.46 43.2357 1370.43 40.012C1372.39 36.7883 1373.42 33.0788 1373.39 29.3014" fill="#CCCCCC"/>
		<path d="M1396.12 426.128V301.432C1396.12 293.702 1401.26 287.411 1407.54 287.411C1413.81 287.411 1418.98 293.702 1418.98 301.432" stroke="#8C6238" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
		<path d="M1334.47 415.881V429.363H1307.85V425.409C1307.8 423.812 1308.18 422.231 1308.96 420.837C1309.74 419.443 1310.89 418.289 1312.27 417.499C1314.2 416.324 1316.36 415.578 1318.6 415.312L1334.41 415.881H1334.47Z" fill="#231F20"/>
		<path d="M1368.22 425.409V429.363H1341.61V415.881L1357.44 415.312C1359.68 415.578 1361.84 416.324 1363.77 417.499C1364.84 418.098 1365.77 418.918 1366.49 419.905C1367.22 420.892 1367.73 422.022 1367.98 423.222C1368.13 423.941 1368.2 424.674 1368.19 425.409" fill="#231F20"/>
		<path d="M1381.22 254.125C1381.79 237.736 1294.29 237.736 1294.86 254.125C1295.81 281.748 1294.14 399.523 1292.88 421.903H1383.19C1381.93 399.433 1380.2 281.658 1381.22 254.125Z" fill="#21283A"/>
		<path d="M1274.99 277.194C1277.11 292.354 1277.8 297.567 1284.76 299.455C1286.73 299.844 1287.75 299.185 1287.75 297.507L1288.79 278.602L1274.99 277.194Z" fill="#885240"/>
		<path d="M1401.11 270.663L1418.77 289.149L1419.54 290.946L1418.14 294.512L1416.44 295.021L1391.46 284.115L1388.18 279.951L1387.94 275.846L1401.11 270.663Z" fill="#885240"/>
		<path d="M1408.31 277.254L1401.05 277.824L1387.34 278.992L1386.36 254.125L1385.34 228.329L1378.56 183.388C1378.41 178.654 1375.87 179.793 1376.17 184.497C1375.45 215.476 1379.99 231.774 1381.31 254.215H1294.83C1296.14 231.774 1300.65 215.476 1299.96 184.497C1300.23 179.793 1297.72 178.505 1297.57 183.388L1290.76 228.329L1289.78 254.125L1288.79 278.992L1275.14 277.824L1267.85 277.254L1268.18 227.31L1276.69 156.753C1277.17 152.759 1278.05 148.824 1279.32 145.009C1286.37 126.463 1292.62 118.554 1318.16 118.554H1358.1C1383.61 118.554 1389.85 126.463 1396.93 145.009C1398.19 148.828 1399.07 152.762 1399.56 156.753L1407.95 227.37L1408.13 254.185L1408.31 277.254Z" fill="#8850AA"/>
		<path d="M1321.8 118.733C1332.61 139.076 1343.43 137.938 1354.24 118.733C1351.26 116.726 1349.82 110.614 1349.31 106.3C1348.21 107.049 1347.01 107.768 1345.76 108.487C1339.31 112.112 1337.01 112.142 1330.55 108.487C1329.27 107.768 1328.07 107.019 1326.91 106.27C1326.07 110.284 1324.55 116.486 1321.8 118.703" fill="#784836"/>
		<path d="M1351.02 104.532C1355.59 99.4685 1357.35 93.7162 1357.98 83.979C1358.54 78.7659 1358.22 72.9236 1354 69.9874C1347.04 65.1338 1340.56 81.4323 1319.38 74.2718C1318.55 73.9722 1318.34 75.9496 1318.37 78.2566C1318.37 88.3832 1318.87 97.611 1325.51 104.502C1335.57 114.988 1340.98 115.647 1351.02 104.502" fill="#885240"/>
		<path d="M1230.84 249.99C1228.42 265.15 1226.21 279.411 1223.16 279.232C1220.92 279.621 1216.62 280.46 1216.59 278.782L1215.28 251.398L1230.84 249.99Z" fill="#B65230"/>
		<path d="M1133.52 137.399L1119.42 162.506L1089.36 165.981L1089.06 164.303L1109.59 124.246L1133.52 137.399Z" fill="#F9987F"/>
		<path d="M1198.67 48.8355C1196.78 66.0029 1196.6 92.3381 1204.46 102.974H1134.02C1139.58 87.1849 1138.98 64.4449 1137.64 49.0453C1137.22 41.4653 1137.01 20.1034 1152.21 14.7105C1158.91 7.54995 1176.26 9.6173 1183.82 14.411C1195.98 22.1707 1199.77 38.7388 1198.76 48.9254" fill="#3D2911"/>
		<path d="M1198.67 48.8355C1196.79 66.0029 1196.61 92.3382 1204.46 102.974H1168.62V10.0967C1173.98 10.3038 1179.22 11.7713 1183.91 14.381C1196.07 22.1408 1199.86 38.7089 1198.85 48.8954" fill="#3D2911"/>
		<path d="M1167.12 320.248L1167.39 422.113C1166.91 430.173 1151.08 429.543 1150.84 422.563L1141.91 320.248H1167.12Z" fill="#B65230"/>
		<path d="M1236.04 252.417L1215.13 254.155L1213 203.402L1208.88 158.461C1208.88 153.757 1206.22 154.896 1206.49 159.599C1205.89 186.384 1209 205.619 1209.48 228.748C1209.69 235.25 1209.84 241.631 1209.63 248.133C1208.88 274.708 1210.47 299.425 1211.6 321.086H1124.97C1126.16 298.616 1127.96 272.94 1126.88 245.286C1126.7 240.313 1126.88 235.22 1127.06 230.306C1127.78 206.667 1130.85 186.444 1130.26 159.659C1129.77 155.393 1128.93 151.174 1127.75 147.046L1126.49 147.286L1123.21 152.948L1109.5 124.306L1118.91 107.498C1124.07 99.259 1136.83 93.8063 1148.33 93.8063H1188.3C1197.95 93.3569 1212.2 98.0606 1218.17 107.738C1223.54 117.526 1226.89 128.295 1228.03 139.406L1235.62 202.503L1236.04 252.417Z" fill="#F9987F"/>
		<path d="M1194.69 320.247L1186 422.503C1185.76 429.483 1170.26 430.053 1169.48 422.053L1169.75 320.188L1194.69 320.247Z" fill="#B65230"/>
		<path d="M1167.36 428.105H1140.74V424.181C1140.74 419.537 1146.72 416.63 1150.63 415.192H1167.36V428.105Z" fill="#3D2913"/>
		<path d="M1169.48 428.105H1196.1V424.181C1196.1 419.537 1190.12 416.63 1186.21 415.192H1169.48V428.105Z" fill="#3D2913"/>
		<path d="M1185.7 69.6284C1183.88 75.7785 1179.83 81.0242 1174.35 84.339C1169.27 87.8144 1167.45 87.8144 1162.4 84.339C1153.44 78.1372 1149.5 72.8641 1148.63 60.79C1147.29 48.8058 1163.57 53.8393 1178.86 41.4656C1180.83 39.8777 1182.36 39.3084 1183.58 43.413C1186.48 51.8433 1187.22 60.8671 1185.73 69.6583" fill="#B65230"/>
		<path d="M1152.21 93.896C1163.03 107.648 1173.84 107.858 1184.66 93.896C1182.47 92.7799 1180.63 91.0899 1179.32 89.0058C1178.02 86.9217 1177.3 84.5217 1177.25 82.0615C1176.35 82.8105 1175.37 83.5296 1174.26 84.2187C1169.18 87.6941 1167.36 87.6941 1162.31 84.2187C1161.62 83.7393 1160.94 83.2899 1160.31 82.8105C1160.23 85.2737 1159.41 87.6555 1157.96 89.6404C1156.5 91.6253 1154.48 93.1197 1152.15 93.9258" fill="#883E24"/>
		<path d="M1154.04 131.856L1194.93 156.663C1200.17 159.999 1203.94 165.218 1205.47 171.25C1207 177.282 1206.17 183.672 1203.15 189.11C1201.71 191.767 1199.76 194.105 1197.4 195.984C1195.04 197.863 1192.33 199.244 1189.42 200.045C1186.52 200.845 1183.48 201.048 1180.49 200.642C1177.51 200.235 1174.64 199.227 1172.05 197.679L1171.42 197.29C1169.36 180.811 1164.49 153.427 1154.04 131.856Z" fill="#DBD9E3"/>
		<path d="M1145.79 126.853L1154.04 131.856C1164.49 153.427 1169.36 180.811 1171.42 197.29L1122.94 167.868C1117.69 164.542 1113.91 159.324 1112.38 153.287C1110.85 147.251 1111.69 140.856 1114.72 135.421C1116.19 132.764 1118.17 130.425 1120.55 128.538C1122.92 126.651 1125.64 125.253 1128.56 124.426C1131.44 123.638 1134.46 123.446 1137.42 123.863C1140.39 124.281 1143.23 125.298 1145.79 126.853Z" fill="#EDE8F2"/>
		<path d="M1141.91 133.683C1145.42 135.891 1147.95 139.369 1148.97 143.396C1150 147.423 1149.44 151.691 1147.41 155.315C1146.44 157.08 1145.14 158.633 1143.56 159.88C1141.99 161.128 1140.18 162.045 1138.24 162.576C1136.31 163.107 1134.29 163.242 1132.3 162.973C1130.31 162.703 1128.4 162.035 1126.67 161.007C1123.17 158.801 1120.65 155.326 1119.63 151.304C1118.61 147.282 1119.18 143.021 1121.21 139.406C1122.17 137.642 1123.47 136.09 1125.04 134.842C1126.61 133.595 1128.42 132.676 1130.35 132.142C1132.28 131.609 1134.3 131.47 1136.28 131.735C1138.27 131.999 1140.18 132.662 1141.91 133.683Z" fill="#C27359"/>
		<path d="M1152.39 171.763C1166.49 176.197 1179.76 180.302 1179.28 183.448C1179.28 185.785 1179.64 190.369 1178.05 190.189L1151.86 188.062L1152.39 171.763Z" fill="#B65230"/>
		<path d="M1158.88 171.763L1154.04 193.604L1094.83 177.306L1090.53 172.272L1089.36 165.981L1115.62 162.505L1154.63 170.685L1158.88 171.763Z" fill="#F9987F"/>
		<path d="M242.744 174.879C242.744 174.879 237.218 110.374 244.387 107.858C251.557 105.341 281.638 110.854 281.638 110.854C281.638 110.854 286.836 130.298 287.224 134.013C285.698 134.447 284.14 134.757 282.564 134.942C282.564 134.942 283.251 150.282 280.324 152.529C277.396 154.776 271.81 153.338 269.689 155.855C267.568 158.371 267.18 181.111 267.18 181.111L242.744 174.879Z" fill="#B65230"/>
		<path d="M241.161 150.941C243.464 149.427 245.486 147.52 247.135 145.308C250.84 139.825 246.777 134.492 248.091 128.62C248.703 126.495 249.955 124.611 251.676 123.227C252.483 122.508 254.424 120.231 255.679 120.86C256.127 121.1 256.217 121.729 256.277 122.298C256.277 123.916 255.858 129.369 258.129 129.759C260.399 130.148 260.488 117.115 260.608 115.438C260.996 110.944 263.028 108.996 267.359 108.906C270.002 108.96 272.633 109.271 275.216 109.835C277.583 110.661 280.11 110.918 282.594 110.584C283.457 110.214 284.216 109.638 284.805 108.906C287.274 106.465 288.951 103.332 289.614 99.918C289.775 97.3849 289.271 94.8538 288.151 92.5777C286.239 88.6388 283.461 85.1869 280.025 82.4828C276.588 79.7787 272.584 77.893 268.315 76.9683C269.249 77.7549 270.128 78.6058 270.944 79.5149C271.234 79.8577 271.393 80.2934 271.392 80.7433C271.392 83.1701 263.715 81.5522 262.43 81.4024C248.032 79.7546 231.632 84.6382 225.747 99.8281C225.307 100.922 224.938 102.043 224.641 103.184C223.393 107.874 223.291 112.797 224.343 117.535C225.925 123.283 228.405 128.743 231.691 133.713C235.037 139.346 237.935 145.278 241.161 151.031" fill="#38231B"/>
		<path d="M228.554 310.63C228.554 310.63 218.129 231.924 223.088 200.975C224.94 189.53 241.878 162.476 241.878 162.476L267.568 171.254C267.568 171.254 280.294 200.076 282.803 212.599C287.373 235.22 293.288 312.038 293.288 312.038L228.554 310.63Z" fill="#8850AA"/>
		<path d="M276.143 267.008C276.143 267.008 302.162 314.255 302.64 314.255C302.64 313.536 304.731 313.087 308.614 313.596L355.185 318.959L358.501 323.723L360.353 399.882C359.069 408.541 386.82 407.312 387.059 400.332C387.059 400.332 385.327 350.687 387.985 329.805C389.061 321.206 386.133 295.949 379.98 291.485C365.043 280.759 320.444 273.988 320.444 273.988C312.079 271.891 285.284 267.846 276.202 266.888" fill="#663627"/>
		<path d="M359.038 412.076H395.094V408.152C395.143 405.238 394.038 402.423 392.023 400.323C390.008 398.223 387.246 397.009 384.34 396.946H358.979L359.038 412.076Z" fill="#412311"/>
		<path d="M221.834 250.02V319.259H331.466V265.48C331.514 261.448 329.971 257.56 327.173 254.664C324.376 251.768 320.55 250.099 316.53 250.02H221.834Z" fill="#21283A"/>
		<path d="M221.834 250.02V319.259H331.466V265.48C331.514 261.448 329.971 257.56 327.173 254.664C324.376 251.768 320.55 250.099 316.53 250.02H221.834Z" stroke="#11161D" strokeMiterlimit="10"/>
		<path d="M155.307 341.22C155.307 387.658 191.154 425.319 235.694 425.319C280.234 425.319 316.081 387.658 316.081 341.22C316.081 294.781 280.234 257.181 235.694 257.181C191.154 257.181 155.307 294.721 155.307 341.22Z" stroke="#21283A" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
		<path d="M235.755 255.443V426.487M331.467 401.141V265.69C331.531 261.63 330.001 257.708 327.206 254.77C324.411 251.833 320.577 250.116 316.53 249.99H221.177L331.467 401.141ZM293.708 401.44L177.802 280.49L293.708 401.44ZM317.695 340.98H153.814H317.695ZM293.708 280.49L177.802 401.44L293.708 280.49Z" stroke="#21283A" strokeMiterlimit="10" strokeLinecap="round"/>
		<path d="M221.564 319.738V200.406C221.629 196.355 220.095 192.442 217.298 189.519C214.501 186.596 210.666 184.899 206.628 184.796H181.535M221.594 319.738H339.292C346.581 319.738 352.526 326.749 352.526 335.318V411.507H396.438L221.594 319.738Z" stroke="#21283A" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
		<path d="M353.541 370.94H333.078" stroke="#21283A" strokeMiterlimit="10" strokeLinecap="round"/>
		<path d="M315.633 413.125C315.773 416.391 316.867 419.544 318.779 422.192C320.69 424.84 323.335 426.867 326.385 428.02C329.435 429.174 332.756 429.404 335.935 428.681C339.114 427.958 342.011 426.315 344.267 423.955C346.523 421.595 348.038 418.622 348.624 415.406C349.21 412.19 348.842 408.872 347.564 405.865C346.286 402.857 344.156 400.292 341.437 398.488C338.718 396.684 335.531 395.721 332.272 395.718C330.039 395.764 327.838 396.252 325.794 397.152C323.749 398.052 321.902 399.347 320.357 400.964C318.811 402.58 317.599 404.487 316.788 406.573C315.978 408.66 315.585 410.886 315.633 413.125Z" fill="#21283A"/>
		<path d="M315.633 413.125C315.773 416.391 316.867 419.544 318.779 422.192C320.69 424.84 323.335 426.867 326.385 428.02C329.435 429.174 332.756 429.404 335.935 428.681C339.114 427.958 342.011 426.315 344.267 423.955C346.523 421.595 348.038 418.622 348.624 415.406C349.21 412.19 348.842 408.872 347.564 405.865C346.286 402.857 344.156 400.292 341.437 398.488C338.718 396.684 335.531 395.721 332.272 395.718C330.039 395.764 327.838 396.252 325.794 397.152C323.749 398.052 321.902 399.347 320.357 400.964C318.811 402.58 317.599 404.487 316.788 406.573C315.978 408.66 315.585 410.886 315.633 413.125V413.125Z" stroke="#21283A" strokeMiterlimit="10"/>
		<path d="M267.241 171.164L240.355 172.932C234.112 175.928 225.658 205.199 225.658 205.199L233.783 261.854L244.896 316.233L270.407 313.596L261.445 259.248L258.817 211.761C258.279 207.087 267.3 171.164 267.3 171.164" fill="#8850AA"/>
		<path d="M250.721 315.753C257.412 332.711 259.683 338.583 269.481 339.212C272.229 339.212 273.304 338.194 272.916 336.216L269.212 313.716L250.721 315.753Z" fill="#B65230"/>
		<path d="M430.941 252.237C433.212 267.397 433.929 272.611 441.397 274.498C443.488 274.888 444.384 274.228 444.384 272.551L445.489 253.675L430.941 252.237Z" fill="#663627"/>
		<path d="M552.942 252.237C550.671 267.397 549.954 272.611 542.486 274.498C540.395 274.888 539.499 274.228 539.349 272.551L538.244 253.675L552.942 252.237Z" fill="#663627"/>
		<path d="M476.855 93.8964L492.001 113.341L507.146 93.8964C503.233 92.4583 501.172 86.2265 500.843 81.7324C494.689 87.0953 490.089 86.8856 483.935 81.7324C483.756 86.2864 481.784 92.3085 476.855 93.7166" fill="#E3B372"/>
		<path d="M492.002 113.341L507.147 93.8964C503.234 92.4583 501.173 86.2265 500.874 81.7324C499.713 82.9511 498.324 83.9278 496.786 84.6065C495.249 85.2852 493.592 85.6524 491.912 85.6871L492.002 113.341Z" fill="#C9995B"/>
		<path d="M490.687 320.248L490.956 422.113C490.478 430.173 476.796 429.543 476.557 422.563L467.596 320.308L490.687 320.248Z" fill="#F5C78A"/>
		<path d="M516.109 320.247L507.416 422.503C507.177 429.483 493.824 430.053 493.047 422.053L493.316 320.188L516.109 320.247Z" fill="#E3B372"/>
		<path d="M490.925 428.105H464.309V424.181C464.309 419.537 472.464 416.63 476.258 415.192H490.835L490.925 428.105Z" fill="#412311"/>
		<path d="M493.047 428.105H519.664V424.181C519.664 419.537 511.508 416.63 507.714 415.192H493.137L493.047 428.105Z" fill="#412311"/>
		<path d="M506.429 93.7163C528.445 95.0645 547.534 102.465 548.788 126.972L550.133 193.515L554.972 252.417L537.048 254.155L532.627 199.027L531.671 148.394C531.074 131.946 525.219 198.039 525.368 200.286C525.816 206.757 530.536 219.79 531.701 234.051H452.21C453.375 219.79 458.185 206.757 458.543 200.286C458.543 198.039 452.837 131.946 452.24 148.394L451.284 199.027L446.863 254.155L428.939 252.417L433.928 193.515L435.302 126.972C436.527 102.465 455.616 95.0645 477.632 93.7163H506.429Z" fill="#21283A"/>
		<path d="M506.43 93.7163C528.447 95.0645 547.535 102.465 548.79 126.972L550.134 193.515L554.973 252.417L537.05 254.155L532.629 199.027L531.673 148.394C531.075 131.946 525.22 198.039 525.37 200.286C525.818 206.757 530.538 219.79 531.703 234.051H492.002V93.7163H506.43Z" fill="#21283A"/>
		<path d="M533.345 257.81C533.883 241.421 532.449 216.704 525.578 201.694H458.395C451.524 216.674 450.09 241.421 450.658 257.81C451.554 285.433 449.941 401.62 448.746 424.061H535.227C534.032 401.59 532.419 285.433 533.345 257.81Z" fill="#21283A"/>
		<path d="M547.445 117.925C547.445 110.165 523.547 75.3508 521.635 58.6328C519.066 37.2111 523.188 22.141 510.911 13.4525C505.325 9.49771 494.988 8.89844 492.479 8.89844C489.97 8.89844 479.634 9.49771 474.078 13.4525C461.8 22.141 465.893 37.2111 463.353 58.6328C461.382 75.3508 437.394 110.165 437.544 117.925C437.544 124.306 476.169 162.356 492.479 162.476C508.79 162.476 547.325 124.306 547.445 117.925Z" fill="#8850AA"/>
		<path d="M513.27 43.9822C513.09 42.4542 497.138 40.7764 492.359 40.9862C487.519 40.6866 471.597 42.3643 471.448 43.9822C470.379 53.3431 471.974 62.8162 476.048 71.3061C479.036 77.478 485.578 87.4249 492.269 87.335H492.628C499.289 87.335 505.861 77.478 508.819 71.3061C512.893 62.8162 514.488 53.3431 513.419 43.9822" fill="#663627"/>
		<path d="M635.837 319.109H591.028C590.849 324.142 590.879 339.123 591.327 343.856L596.136 425.109C596.136 428.824 612.118 429.483 611.431 424.899L612.626 347.991C612.626 345.864 613.044 344.785 613.403 344.785C613.761 344.785 614.15 345.864 614.18 347.991L615.404 424.899C614.717 429.483 630.58 428.824 630.699 425.109L635.538 343.737C635.986 339.003 636.016 324.023 635.837 318.989" fill="#215485"/>
		<path d="M637.869 284.924L638.586 337.175V349.159C638.586 350.268 639.034 350.687 640.11 350.447C643.784 349.249 644.889 346.013 645.905 336.486C647.249 323.633 648.384 284.834 648.384 284.834L637.869 284.924Z" fill="#663627"/>
		<path d="M589.027 284.924L588.31 337.175V349.159C588.31 350.268 587.832 350.687 586.786 350.447C583.112 349.249 582.007 346.013 580.991 336.486C579.647 323.633 578.512 284.834 578.512 284.834L589.027 284.924Z" fill="#663627"/>
		<path d="M649.878 276.535C648.922 267.547 647.936 254.544 639.184 251.578L622.724 247.293H604.203L587.743 251.578C578.99 254.574 578.005 267.427 577.079 276.535L576.75 286.092H590.641L590.88 282.946C590.88 280.46 591.716 281.059 591.567 283.546C591.955 300.833 591.567 305.926 591.029 318.839C591.029 319.348 591.029 319.888 591.029 320.487L613.553 323.093L636.047 320.487C636.047 319.888 636.047 319.348 636.047 318.839C635.539 305.926 635.121 300.863 635.509 283.546C635.509 281.059 636.137 280.55 636.197 282.946L636.435 286.092H650.326L649.878 276.535Z" fill="#F9987F"/>
		<path d="M620.96 239.145H605.606C605.568 242.11 604.754 245.013 603.246 247.563C606.233 252.327 609.818 254.724 613.283 254.724C616.748 254.724 620.243 252.327 623.35 247.563C621.831 245.016 621.007 242.112 620.96 239.145Z" fill="#4D291C"/>
		<path d="M611.521 428.105H594.016V426.038C594.014 424.499 594.602 423.019 595.659 421.903C596.715 420.788 598.159 420.123 599.691 420.046H611.64L611.521 428.105Z" fill="#1B110F"/>
		<path d="M615.314 428.105H632.82V426.038C632.821 424.494 632.229 423.01 631.166 421.894C630.103 420.777 628.652 420.115 627.114 420.046H615.314V428.105Z" fill="#1B110F"/>
		<path d="M628.101 194.414C636.077 198.398 636.495 207.117 635.928 215.596C635.062 228.389 636.466 225.842 627.145 235.909H599.782C590.133 225.003 591.866 230.486 590.82 216.045C590.253 208.884 590.163 201.424 595.57 196.331C598.509 193.889 601.938 192.111 605.624 191.119C609.31 190.126 613.166 189.942 616.929 190.579C619.288 190.842 621.546 191.686 623.501 193.035C625.161 193.199 626.769 193.709 628.221 194.533" fill="#1B110F"/>
		<path d="M596.884 230.366C597.273 231.947 597.772 233.499 598.378 235.01C600.133 239.119 603.078 242.606 606.831 245.017C608.725 246.339 610.977 247.048 613.284 247.048C615.591 247.048 617.843 246.339 619.736 245.017C622.357 243.465 624.605 241.353 626.319 238.832C628.033 236.31 629.172 233.44 629.654 230.426C629.657 230.373 629.675 230.321 629.707 230.279C629.739 230.236 629.783 230.204 629.833 230.186C630.813 229.667 631.618 228.87 632.149 227.895C632.68 226.921 632.914 225.81 632.821 224.703C632.821 222.486 631.865 220.839 630.58 220.599C630.506 220.599 630.434 220.572 630.379 220.522C630.323 220.472 630.289 220.403 630.281 220.329C630.083 218.183 629.622 216.07 628.907 214.037C628.638 213.438 628.25 212.749 627.473 213.229C623.287 215.502 618.581 216.638 613.822 216.524C613.822 216.524 614.897 215.266 616.391 213.139C615.907 213.253 615.457 213.479 615.076 213.798C611.712 216.386 607.722 218.03 603.516 218.562C603.516 218.562 603.993 217.663 604.681 216.075C602.85 216.32 601.084 216.92 599.483 217.843C598.566 218.47 597.842 219.342 597.392 220.359C597.348 220.445 597.278 220.515 597.192 220.558C597.106 220.601 597.009 220.616 596.914 220.599C595.36 220.389 594.106 222.187 594.106 224.703C594.035 225.747 594.237 226.791 594.693 227.732C595.149 228.673 595.842 229.478 596.705 230.066C596.765 230.094 596.819 230.135 596.86 230.187C596.902 230.239 596.93 230.301 596.944 230.366" fill="#663627"/>
		<path d="M110.11 233.362C110.468 242.92 110.379 259.937 109.572 268.865L99.3556 422.592C99.1167 429.573 71.365 430.802 72.6197 422.143L70.3494 262.244C70.3494 258.23 69.6025 256.252 68.8856 256.252C68.1686 256.252 67.4816 258.259 67.4219 262.244L65.1217 422.113C66.4062 430.772 38.6247 429.543 38.2364 422.563L27.99 268.895C27.1835 259.907 27.0939 242.95 27.4523 233.392L110.11 233.362Z" fill="#21283A"/>
		<path d="M86.3017 85.687L118.624 97.6712C121.364 99.0018 123.773 100.93 125.675 103.314C127.577 105.699 128.924 108.48 129.617 111.453L134.695 141.743L137.682 198.998L135.89 254.514L114.979 256.282L115.159 201.155L111.574 153.877C111.395 149.143 109.961 150.282 110.23 154.986C109.513 187.613 109.214 208.435 110.23 232.883C110.23 233.842 110.23 234.92 110.23 235.879H27.6619C27.6619 234.77 27.6619 233.692 27.6619 232.883C28.6178 208.525 28.3191 187.702 27.6619 154.986C27.9307 150.282 26.4968 149.143 26.3176 153.877L22.7329 201.155L22.9122 256.282L2.00136 254.514L0.208984 198.998L3.19624 141.743L8.27461 111.453C8.96743 108.48 10.314 105.699 12.216 103.314C14.118 100.93 16.5273 99.0018 19.2677 97.6712L51.5898 85.687H69.0354H86.3017Z" fill="white"/>
		<path d="M65.3009 428.105H32.232V424.18C32.1839 421.266 33.2882 418.452 35.3035 416.352C37.3187 414.252 40.0809 413.038 42.9861 412.975H65.3009V428.105Z" fill="#231F20"/>
		<path d="M8.27539 255.203C11.2626 273.179 12.2485 279.172 19.2088 281.538C21.2102 282.018 22.196 281.179 22.0466 279.142V256.342L8.27539 255.203Z" fill="#695445"/>
		<path d="M72.4707 428.105H105.54V424.18C105.588 421.266 104.483 418.452 102.468 416.352C100.453 414.252 97.6907 413.038 94.7855 412.975H72.4707V428.105Z" fill="#231F20"/>
		<path d="M129.378 255.203C126.391 273.179 125.435 279.172 118.445 281.538C116.443 282.018 115.457 281.179 115.607 279.142V256.342L129.378 255.203Z" fill="#695445"/>
		<path d="M50.0664 86.1962C52.7088 83.1447 54.4654 79.4243 55.1448 75.4404H82.6873C83.3667 79.4243 85.1232 83.1447 87.7656 86.1962L68.916 103.364L50.0664 86.1962Z" fill="#504034"/>
		<path d="M68.9161 9.31779C65.2041 9.18447 61.5027 9.79104 58.0261 11.1025C54.5495 12.4139 51.3669 14.404 48.6625 16.9577C43.3751 22.5303 43.3751 29.8107 43.614 37.0012C43.614 39.2183 43.8829 41.6152 44.2115 44.4914L41.8516 46.3789C41.8578 51.6141 42.7466 56.8106 44.4804 61.7486L46.8104 63.0969L47.3182 65.0143C49.2599 72.5344 56.28 82.5112 63.6585 85.9866C67.1355 86.616 70.6967 86.616 74.1737 85.9866C81.5522 82.6311 88.5722 72.6542 90.5438 65.0143L91.0218 63.0969L93.3518 61.7486C95.0856 56.8106 95.9744 51.6141 95.9806 46.3789L93.6207 44.4914C93.9493 41.4953 94.1584 39.2183 94.2182 37.0012C94.4571 29.8107 94.4571 22.5303 89.1697 16.9577C86.4663 14.4027 83.2838 12.4117 79.807 11.1002C76.3302 9.78866 72.6283 9.18281 68.9161 9.31779Z" fill="#695445"/>
		<path d="M43.2257 45.3005C42.384 41.2178 41.924 37.0652 41.8516 32.8968C41.9964 28.691 42.6382 24.5174 43.7635 20.4632C45.434 17.0078 48.0701 14.114 51.351 12.1342C54.7037 10.1113 58.6523 9.31713 62.5234 9.88719C65.5107 8.11952 73.5463 8.44904 77.9675 9.64745C80.2677 10.4863 82.6575 10.786 84.9876 11.8046C89.9601 14.9299 93.5433 19.854 94.9949 25.5565C96.13 28.9121 95.3534 39.6978 94.8157 45.4203L91.1712 48.4163L89.4087 57.1348C86.9592 54.1388 88.9905 40.7764 88.5723 38.5893C88.3333 37.3309 84.9577 33.0466 77.3402 33.4361C72.2619 33.7057 71.3657 35.2038 64.6742 34.5447C57.9828 33.8856 52.4564 31.998 49.738 36.582C47.4676 40.327 51.7095 53.8392 48.1546 56.5357L46.7805 48.4163L43.2257 45.3005Z" fill="black"/>
		<path d="M98.3708 8.83838C98.7164 10.5703 98.6759 12.3574 98.2525 14.0718C97.829 15.7862 97.0329 17.3855 95.9212 18.7553C93.8144 21.5297 91.1474 23.8268 88.0946 25.4964C82.1759 28.6586 75.5626 30.286 68.8567 30.2302C62.1582 30.3041 55.5501 28.6755 49.6486 25.4964C46.5827 23.8466 43.9041 21.5584 41.7922 18.7853C40.6762 17.412 39.8776 15.8076 39.4541 14.0876C39.0305 12.3676 38.9924 10.5749 39.3426 8.83838C40.258 11.68 42.1401 14.1085 44.6599 15.6994C47.0032 17.2021 49.548 18.3622 52.2177 19.1448C57.6225 20.7218 63.228 21.4989 68.8567 21.4517C74.4832 21.496 80.0867 20.7293 85.4956 19.1748C88.1687 18.3875 90.7143 17.2169 93.0535 15.6994C95.5733 14.1085 97.4554 11.68 98.3708 8.83838Z" fill="#F2A63C"/>
		<path d="M1675.64 8.65849C1704.59 4.88347 1710.53 38.6189 1706.38 62.3776C1705.16 69.2984 1704.05 93.866 1707.19 100.308C1710.32 106.749 1702.23 106.779 1699.42 107.049H1643.11C1640.69 107.049 1633.82 105.7 1634.66 102.525C1638.09 89.4319 1636.21 67.5308 1635.43 57.5839C1634.03 39.6076 1636.39 23.8784 1646.28 14.8902C1650.28 11.5111 1655.07 9.19235 1660.2 8.14564C1665.33 7.09894 1670.64 7.35761 1675.64 8.89813" fill="black"/>
		<path d="M1670.89 93.7164H1651.21C1626.17 93.7164 1622.2 101.566 1615.63 119.962C1613.96 124.666 1605.59 163.824 1605.32 166.94C1605.32 166.94 1633.64 154.956 1633.37 159.599C1633.61 169.367 1637.08 177.576 1636.54 185.216C1635.25 202.743 1628.83 215.865 1628.14 232.913C1627.72 241.902 1627.89 250.91 1628.65 259.877H1713.13C1714.12 250.925 1714.51 241.917 1714.3 232.913C1713.61 215.925 1706.59 202.952 1705.34 185.485C1704.8 177.905 1708.32 169.516 1708.5 159.629C1708.2 154.926 1710.68 153.787 1710.86 158.491L1714.92 203.432L1719.53 254.065L1737.06 252.327L1734.07 202.383L1728.49 130.658C1728.25 126.974 1727.5 123.342 1726.25 119.872C1719.67 101.476 1715.7 93.6265 1690.64 93.6265L1670.89 93.7164Z" fill="#F2A63C"/>
		<path d="M1714.3 232.883C1714.92 239.435 1715.08 246.022 1714.81 252.597C1713.58 277.554 1707.64 309.522 1704.35 335.857L1695.39 421.394C1695.12 428.405 1672.03 429.603 1673.46 420.945L1671.49 248.732C1671.34 240.613 1669.52 240.613 1669.37 248.732L1667.4 420.945C1668.83 429.603 1645.74 428.405 1645.47 421.394L1636.51 335.857C1633.22 309.552 1627.28 277.554 1626.05 252.597C1625.77 246.022 1625.94 239.435 1626.56 232.883H1714.3Z" fill="#21283A"/>
		<path d="M1733.18 252.357C1731.06 267.517 1730.4 272.73 1723.53 274.618C1721.59 275.037 1720.54 274.348 1720.54 272.67L1719.53 253.795L1733.18 252.357Z" fill="#B65230"/>
		<path d="M1711.4 227.37C1713.1 244.597 1696.46 260.506 1674 262.933C1651.53 265.36 1632.18 253.316 1630.41 236.118C1628.65 218.921 1645.35 202.982 1667.82 200.555C1690.28 198.128 1709.64 210.143 1711.4 227.37Z" fill="#F2A63C"/>
		<path d="M1711.4 231.894C1711.6 233.92 1711.35 235.964 1710.68 237.886C1710.09 239.783 1709.32 241.619 1708.38 243.369C1706.5 246.838 1704.16 250.033 1701.42 252.866C1698.75 255.698 1695.74 258.193 1692.46 260.297C1689.11 262.353 1685.5 263.965 1681.74 265.09C1674.2 267.314 1666.17 267.241 1658.67 264.88C1656.83 264.309 1655.03 263.628 1653.27 262.843C1648.07 260.399 1643.31 257.098 1639.2 253.076C1642.48 254.827 1645.85 256.397 1649.29 257.78C1652.62 259.135 1656.07 260.139 1659.6 260.776C1666.53 262.232 1673.72 261.944 1680.51 259.937C1683.9 258.976 1687.16 257.608 1690.22 255.862C1693.35 254.165 1696.3 252.158 1699.03 249.87C1701.81 247.497 1704.34 244.847 1706.59 241.961C1709.01 239.09 1710.74 235.696 1711.64 232.044" fill="#F2A63C"/>
		<path d="M1630.59 220.868C1631.58 217.181 1633.25 213.715 1635.52 210.652C1639.86 204.385 1645.52 199.149 1652.1 195.312C1655.42 193.422 1658.96 191.962 1662.65 190.968C1666.37 190.022 1670.22 189.668 1674.06 189.919C1677.87 190.183 1681.62 191.085 1685.14 192.586C1686.89 193.293 1688.59 194.124 1690.22 195.072C1691.82 196.054 1693.37 197.125 1694.85 198.278C1697.79 200.595 1700.45 203.238 1702.8 206.158C1699.82 204.089 1696.72 202.189 1693.53 200.465C1690.48 198.777 1687.24 197.44 1683.89 196.48C1677.37 194.511 1670.43 194.377 1663.84 196.091C1660.53 196.92 1657.34 198.137 1654.31 199.716C1651.16 201.3 1648.17 203.165 1645.35 205.289C1642.48 207.422 1639.79 209.776 1637.29 212.33C1634.66 214.862 1632.38 217.733 1630.5 220.868" fill="#F2A63C"/>
		<path d="M1637.79 128.77L1629.64 173.171V205.109L1605.74 204.42C1605.74 204.42 1604.25 174.46 1605.74 164.513C1607.55 157.213 1609.79 150.03 1612.46 143.001L1637.79 128.77Z" fill="#F2A63C"/>
		<path d="M1654.91 93.7166L1670.89 127.931L1686.87 93.7166C1685.18 92.23 1683.83 90.3829 1682.94 88.3097C1682.05 86.2366 1681.62 83.9896 1681.71 81.7324C1680.68 82.5731 1679.6 83.3533 1678.48 84.0693C1672.71 87.7245 1670.65 87.7245 1664.92 84.0693C1663.45 83.1546 1662.03 82.1542 1660.68 81.0732C1660.4 85.8984 1658.35 90.4484 1654.91 93.8363" fill="#883E24"/>
		<path d="M1678.24 84.0092C1672.27 87.6644 1670.33 87.6344 1664.47 84.0092C1660.55 81.7963 1657.18 78.7319 1654.59 75.0408C1652.01 71.3497 1650.28 67.1253 1649.53 62.6773C1648.69 59.557 1648.77 56.2599 1649.74 53.1798L1679.88 49.7644L1684.51 39.7876L1687.02 48.9555L1691.65 48.4461C1693.69 62.7672 1691.36 75.86 1678.33 84.0392" fill="#B65230"/>
		<path d="M1667.4 428.105H1641.17V424.18C1641.17 418.009 1645.98 414.923 1651.74 414.084L1667.4 411.867V428.105Z" fill="#231F20"/>
		<path d="M1674.42 428.105H1700.68V424.18C1700.68 418.009 1695.84 414.923 1690.07 414.084L1674.42 411.867V428.105Z" fill="#231F20"/>
		<path d="M1652.55 237.707C1664.2 247.114 1675.19 255.893 1673.46 258.679C1672.74 260.926 1671.4 265.3 1669.97 264.671L1646.07 252.896L1652.55 237.707Z" fill="#B65230"/>
		<path d="M1661.9 241.961L1649.62 252.686L1643.59 256.132L1606.01 207.985L1605.35 201.304L1607.95 195.492L1619.54 185.455L1658.11 239.384L1661.9 241.961Z" fill="#F2A63C"/>
		<path d="M1549.1 222.217C1549.46 231.774 1549.4 259.997 1548.56 268.925L1538.32 422.592C1538.08 429.573 1510.33 430.802 1511.43 422.143L1509.34 262.244C1509.34 258.23 1508.6 256.252 1507.88 256.252C1507.16 256.252 1506.47 258.259 1506.41 262.244L1504.11 422.113C1505.4 430.772 1477.62 429.543 1477.23 422.563L1467.01 268.895C1466.18 259.907 1466.09 231.744 1466.45 222.187L1549.1 222.217Z" fill="#61BAFA"/>
		<path d="M1525.29 85.687L1557.62 97.6712C1560.36 99.0001 1562.77 100.927 1564.68 103.312C1566.59 105.696 1567.94 108.478 1568.64 111.453L1573.69 141.743L1574.55 198.998L1572.79 254.514L1553.88 256.282L1554.06 201.155L1550.48 153.877C1550.3 149.143 1548.86 150.282 1549.16 154.986C1548.42 187.613 1548.12 197.23 1549.16 221.588C1549.16 222.546 1549.16 223.625 1549.16 224.734H1466.5C1466.5 223.625 1466.5 222.546 1466.5 221.588C1467.49 197.23 1467.16 187.613 1466.5 154.986C1466.77 150.282 1465.34 149.143 1465.19 153.877L1461.55 201.275L1461.7 256.402L1442.82 254.634L1441.02 199.117L1441.89 141.863L1446.97 111.573C1447.66 108.599 1449.01 105.819 1450.91 103.434C1452.81 101.05 1455.22 99.1217 1457.96 97.7911L1490.28 85.8069H1507.73L1525.29 85.687Z" fill="white"/>
		<path d="M1504.29 428.105H1471.22V424.18C1471.18 421.266 1472.28 418.452 1474.3 416.352C1476.31 414.252 1479.07 413.038 1481.98 412.975H1504.29V428.105Z" fill="#231F20"/>
		<path d="M1447.27 255.203C1450.25 273.179 1451.24 279.172 1458.2 281.538C1460.2 282.018 1461.19 281.179 1461.19 279.142V256.342L1447.27 255.203Z" fill="#663627"/>
		<path d="M1511.4 428.105H1544.47V424.18C1544.52 421.271 1543.42 418.461 1541.41 416.362C1539.4 414.263 1536.65 413.046 1533.75 412.975H1511.4V428.105Z" fill="#231F20"/>
		<path d="M1568.4 255.203C1565.41 273.179 1564.43 279.172 1557.44 281.538C1555.46 282.018 1554.45 281.179 1554.45 279.142V256.342L1568.4 255.203Z" fill="#663627"/>
		<path d="M1489.09 86.1962C1491.71 83.1346 1493.45 79.4175 1494.14 75.4404H1521.68C1522.36 79.4243 1524.11 83.1447 1526.76 86.1962L1507.91 103.364L1489.09 86.1962Z" fill="#4D291C"/>
		<path d="M1507.91 11.3851C1500.54 11.1264 1493.37 13.7969 1487.95 18.8153C1482.79 24.2382 1482.76 31.3387 1482.99 38.3195C1482.99 40.5066 1483.26 42.8136 1483.59 45.5999L1481.26 47.4575C1481.27 52.5601 1482.14 57.6243 1483.83 62.4378L1486.13 63.756L1486.64 65.6136C1488.55 72.9539 1495.6 82.661 1502.74 85.8968C1506.17 86.5256 1509.68 86.5256 1513.11 85.8968C1520.34 82.661 1527.27 72.9539 1529.21 65.6136L1529.69 63.756L1531.99 62.4378C1533.7 57.6297 1534.58 52.5631 1534.58 47.4575L1532.25 45.5999C1532.58 42.8136 1532.76 40.5066 1532.85 38.3195C1533.09 31.3387 1533.06 24.2382 1527.86 18.8153C1522.46 13.7855 1515.28 11.1132 1507.91 11.3851Z" fill="#663627"/>
		<path d="M1524.46 35.3236C1526.19 39.5181 1528.55 46.0195 1527.44 51.2326C1527.27 52.491 1527.77 52.9405 1528.82 52.6109L1530.4 46.6188H1533.39C1533.39 41.8251 1533.39 32.5973 1533.87 27.3242C1534.11 24.0286 1534.88 24.3281 1533.3 21.0924C1529.98 14.2914 1520.07 11.4451 1521.35 10.9658C1522.16 10.9658 1521.35 10.4865 1520.27 10.0071C1513.02 7.01105 1504.44 6.41184 1498.41 10.0071C1492.16 10.2468 1486.19 15.31 1486.46 16.2088C1487.3 15.9392 1487.21 15.8794 1487.41 15.9093C1486.94 16.868 1482.4 20.7928 1481.44 24.0286C1480.72 26.006 1482.4 35.8629 1482.04 46.8284H1485.44L1487.15 52.8205C1488.19 53.1501 1488.67 52.8206 1488.52 51.4424C1488.07 46.1094 1498.56 39.2784 1495.15 44.9409C1497.87 47.0981 1506.71 40.9262 1507.37 41.0161C1508.03 41.106 1506.38 43.2632 1500.17 46.3192C1501.75 46.7662 1503.41 46.8684 1505.04 46.6188C1510.59 45.5412 1515.71 42.8415 1519.74 38.859C1520.66 37.8588 1521.36 36.6744 1521.79 35.3834C1522.22 34.0925 1522.38 32.7242 1522.25 31.3688C1522.52 31.3688 1523.11 32.5673 1524.34 35.5334" fill="black"/>
		<path d="M1504.11 121.849H1474C1474.41 120.734 1475.08 119.733 1475.95 118.93C1476.82 118.127 1477.88 117.546 1479.02 117.235C1479.84 116.981 1480.7 116.85 1481.56 116.846C1482.7 116.875 1483.82 117.144 1484.85 117.634C1485.88 118.124 1486.79 118.825 1487.53 119.692C1488.07 120.261 1488.52 120.92 1489.09 121.639C1489.97 120.06 1491.28 118.754 1492.85 117.864C1494.04 117.207 1495.38 116.857 1496.74 116.846C1498.32 116.859 1499.86 117.341 1501.16 118.232C1502.47 119.122 1503.49 120.381 1504.08 121.849" fill="#B32230"/>
		<path d="M1504.83 125.504C1504.86 125.923 1504.86 126.344 1504.83 126.763H1473.31C1473.28 125.66 1473.35 124.557 1473.52 123.467C1473.6 122.878 1473.76 122.303 1474 121.759H1504.11C1504.63 122.937 1504.88 124.218 1504.83 125.504Z" fill="#D64927"/>
		<path d="M1504.77 126.853C1504.66 128.58 1504.25 130.275 1503.55 131.856H1474.51C1473.77 130.29 1473.36 128.586 1473.31 126.853H1504.77Z" fill="#F2A63C"/>
		<path d="M1503.55 131.856C1502.83 133.435 1501.82 134.86 1500.56 136.05L1499.72 136.859H1478.51L1476.93 135.301C1475.93 134.305 1475.11 133.139 1474.51 131.856H1503.55Z" fill="#B5C24F"/>
		<path d="M1499.72 136.859L1494.41 141.863H1483.77C1482.01 140.215 1480.28 138.567 1478.54 136.859H1499.72Z" fill="#548AB6"/>
		<path d="M1494.41 141.863L1492.37 143.75L1489.09 146.747C1487.36 145.069 1485.56 143.421 1483.8 141.743L1494.41 141.863Z" fill="#803155"/>
	</svg>
)