import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import useSiteSettings from '~utils/useSiteSettings'
import Section from '~components/Section'
import Multi from '~components/Multi'
import ImageText from '~components/ImageText'
import QuoteModule from '~components/QuoteModule'
import PeopleMarquee from '~components/PeopleMarquee'
import Supporters from '~components/Supporters'
import VerticalScroller from '~components/VerticalScroller'
import StatsSection from '~components/StatsSection'
import { mobile } from '~styles/global'

const About = ({ data }) => {
	const { page } = data

	return (
		<>	
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<Intro content={page.intro}/>
			<QuoteModule quote={page.quote} attribution={page.attribution} image={''} color='#FF7F5F' marqueeImage>
				<PeopleMarquee color='lightOrange'/>
			</QuoteModule>
			<Supporters content={page.supporters}/>
			<VerticalScroller title={page.impactHeading} text={page?.impactText} images={page?.impactImages}/>
			<StatsSection title={page?.progressHeading} text={page?.progressText} stats={page?.progressStats} />
			<DataViewer data={page} name="page"/>
		</>
	)
}

const Intro = styled(ImageText)`
	> div {
		padding-top: 291px;
		${mobile}{
			padding-top: 191px;
		}
	}
`


export const query = graphql`
  query AboutQuery {
    page: sanityAboutPage {
      title {
				...multiString
			}
			intro {
				title {
					...multiString
				}
				text {
					...multiRichText
				}
				bottomImage {
					...imageWithCaption
				}
				links {
					...button
				}
			}
			quote {
					...multiText
				}
				attribution {
					...multiString
				}
			supporters {
				logo {
					...imageWithAlt
				}
				text {
					...multiText
				}
				button {
					...button
				}
			}
			impactHeading {
				...multiString
			}
			impactText {
				...multiText
			}
			impactImages {
				...imageWithAlt
			}
			progressHeading {
				...multiString
			}
			progressText {
				...multiText
			}
			progressStats {
				percentage
				text {
					...multiText
				}
			}
			seo{
				...seo
			}
    }
  }
`

About.propTypes = {
	data: PropTypes.object,
}

export default About
