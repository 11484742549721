import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, ArrowIcon, ReadMore } from '~styles/global'
import Section from '~components/Section'
import SanityLink from '~components/SanityLink'
import Multi from '~components/Multi'
import Image from '~components/Image'
import '~styles/flickity.css'
import * as Flickity from 'react-flickity-component'

const Supporters = ({ className, content }) => {

	const flickityOptions = {
		initialIndex: 1,
		prevNextButtons: false,
		pageDots: false,
		wrapAround: true,
		cellAlign: 'left',
		autoPlay: true,
		contain: true,
	}

	return (
		<Wrap className={className}>
			{content.map((supporter, i) => (
				<SupporterDesk key={'supporter' + i}>
					<Logo image={supporter?.logo}/>
					<Text className='sm'>
						<Multi>
							{supporter?.text}
						</Multi>
					</Text>
					<Center>
						<StyledReadMore link={supporter?.button?.link} className='link'>
							<Multi>{supporter?.button.text}</Multi><ArrowIcon />
						</StyledReadMore>
					</Center>
				</SupporterDesk>
			))}
			<Slider				
				className={'carousel'} // default ''
				elementType={'div'} // default 'div'
				options={flickityOptions} // takes flickity options {}
				disableImagesLoaded={false} // default false
				reloadOnUpdate // default false
				static // default false
			>
				{content.map((supporter, i) => (
					<SupporterMob key={'supporter' + i}>
						<Logo image={supporter?.logo}/>
						<Text className='sm'>
							<Multi>
								{supporter?.text}
							</Multi>
						</Text>
						<Center>
							<StyledReadMore link={supporter?.button?.link} className='link'>
								<Multi>{supporter?.button.text}</Multi><ArrowIcon />
							</StyledReadMore>
						</Center>
					</SupporterMob>
				))}
				{content.map((supporter, i) => (
					<SupporterMob key={'supporter' + i}>
						<Logo image={supporter?.logo}/>
						<Text className='sm'>
							<Multi>
								{supporter?.text}
							</Multi>
						</Text>
						<Center>
							<StyledReadMore link={supporter?.button?.link} className='link'>
								<Multi>{supporter?.button.text}</Multi><ArrowIcon />
							</StyledReadMore>
						</Center>
					</SupporterMob>
				))}
			</Slider>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	>div{
	padding-top: 145px;
	padding-bottom: 145px;
	grid-column-gap: 80px;
		${mobile}{
			grid-column-gap: 20px;
			min-height: 100vh;
			padding-top: 0;
			padding-bottom: 0;
		}
	}
`
const StyledReadMore = styled(ReadMore)`
	display: inline-block;
`
const SupporterDesk = styled.div`
	grid-column: span 4;
	${mobile}{
		display: none
	}
`
const Logo = styled(Image)`
	margin-bottom: 60px;
	> div{
		max-height: 120px;
		${mobile}{
			max-height: 70px;
		}
	}
`
const Text = styled.div`
	margin-bottom: 40px;
	text-align: center;
`
const Center = styled.div`
	text-align: center;
`
const Slider = styled(Flickity)`
	grid-column: span 12;
	align-self: center;
`
const SupporterMob = styled.div`
	display: none;
	${mobile}{
		display: block;
	}
`
Supporters.propTypes = {
	className: PropTypes.string,
	content: PropTypes.array,
}

export default Supporters